import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../lib/useWindowDeminsions';
import './Navbar.css';

const Navbar = () => {
  const {width} = useWindowDimensions();
  const [showMobileNav, setShowMobileNav] = useState(false);

  return (
    <>
      { 
        width > 600 ?
          <div className='navbar'>
            <Link to='/' className='navbar-brand-link'><img src='/assets/logo.png' alt='ACS Logo' className='navbar-brand-image'/></Link>
            <nav className='navbar-navigation'>
              <Link className='navbar-navigation-link' to='/info' id='info-link'>Info</Link>
              <a className='navbar-navigation-link' href='mailto:help@makeathon.info' id='contact-link'>Contact</a>
              <a className='btn btn-primary' href='/rsvp' id='rsvp-link'>RSVP</a>
            </nav>
          </div>
        :
          <>
            <div className='navbar-mobile'>
              <Link to='/' className='navbar-mobile-brand-link'><img src='/assets/logo.png' alt='ACS Logo' className='navbar-brand-image'/></Link>
              <button className='menu-btn' onClick={() => setShowMobileNav(!showMobileNav)}><img src='/assets/menu.png' alt='Menu' className='menu-btn-image'/></button>
            </div>
            {
              showMobileNav ?
                <nav className='navbar-mobile-navigation'>
                  <button className='close-btn' onClick={() => setShowMobileNav(!showMobileNav)}>X</button>
                  <Link className='navbar-mobile-navigation-link' to='/' onClick={() => setShowMobileNav(!showMobileNav)} id='info-link-mobile'>Home</Link>
                  <Link className='navbar-mobile-navigation-link' to='/info' onClick={() => setShowMobileNav(!showMobileNav)} id='info-link-mobile'>Info</Link>
                  <a className='navbar-mobile-navigation-link' href='mailto:help@makeathon.info' onClick={() => setShowMobileNav(!showMobileNav)} id='contact-link-mobile'>Contact</a>
                  <a className='btn btn-primary' href='/rsvp' onClick={() => setShowMobileNav(!showMobileNav)} id='rsvp-link'>RSVP</a>
                </nav>
              :
                <></>
            }
          </>
      }
    </>
  );
}

export default Navbar;
