
interface RedirectProps {
  to: string,
}

const Redirect = ({to}:RedirectProps) => {
  window.location.href = to;
  return null;
}

export default Redirect;
