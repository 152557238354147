import React from 'react';
import './App.css';
import Home from './components/Home';
import Info from './components/Info';
import Navbar from './components/Navbar';
import { Routes, Route } from 'react-router-dom';
import Redirect from './components/Redirect';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/info' element={<Info />}/>
        <Route path='/rsvp' element={<Redirect to='https://forms.gle/VQwWEGKoiF64kW666'/>}/>
      </Routes>
    </div>
  );
}

export default App;
