import React, { useEffect, useRef, useState } from 'react';
// @ts-ignorets-ignore
import NET from 'vanta/dist/vanta.net.min'
import './Home.css';

const Home = () => {
  const [vantaEffect, setVantaEffect] = useState<any>()
  const home = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(NET({
        el: home.current,
        backgroundColor: 0x202020,
        color: 0xe98a31,
        points: 9.00,
        maxDistance: 22.00,
        spacing: 17.00,
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])


  return (
    <div className='page home animated-birds' ref={home}>
      <div className='home-hero'>
        <h1>MAKE-A-THON</h1>
        <p>April 1st - 2nd, 2022 • Online Event</p>
        <a href='/rsvp' className='btn btn-primary'>Register Now</a>
      </div>
    </div>
  );
}

export default Home;
