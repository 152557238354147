import React from 'react';
import './Info.css';

const Info = () => {
  return (
    <div className='page info'>
      <h1 className='page-title'>Event Information</h1>
      <div className='page-body'>
        <section className='page-section'>
          <h2 className='page-section-header'>When?</h2>
          <p>April 1st - 2nd</p>
        </section>
        <section className='page-section'>
          <h2 className='page-section-header'>What?</h2>
          <p>A MAKE-A-THON is a program in which participants work together to build a prototype for a project. Projects can be anything from business ideas to software products, you can make anything.</p>
        </section>
        <section className='page-section'>
          <h2 className='page-section-header'>Why?</h2>
          <p>A MAKE-A-THON is a great way to build teamworking skills and learn from mentors and guest speakers. We have some great prizes... scholarships, Dominican swag, and a feature in a Dominican University news article.</p>
        </section>
        <section className='page-section'>
          <h2 className='page-section-header'>Who?</h2>
          <p>The MAKE-A-THON will be hosted by The Lepage Center for Global Innovation in partnership with the Applied Computer Science Program, at Dominican. This event is open to everyone.</p>
        </section>
        <section className='page-section'>
          <h2 className='page-section-header'>Where?</h2>
          <p>Virtually via Zoom</p>
        </section>
      </div>
    </div>
  );
}

export default Info;
